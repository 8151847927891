<template>
    <div id="homepageCarousel">
        <Loader :state="loader_state"></Loader>
        <div class="row">
            <div class="col-md-12">
                <b-carousel
                        id="homepage_carousel"
                        v-model="slide"
                        :interval=10000
                        img-width="1200"
                        img-height="480"
                        indicators
                >
                    <b-carousel-slide v-for="(slider, index) in sliderData" :key="index" :img-src="baseURL+slider.url"></b-carousel-slide>
                </b-carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Loader from '../shared/alhaq_loader'
    import config from '../../assets/config'
    export default {
        components: { Loader },
        data() {
            return {
                loader_state: false,
                slide: 0,
                baseURL: config.baseURL,
                sliderData: []
            }
        },
        mounted () {
             this.loader_state = true
             this.getSlider().then(response => {
                this.sliderData = response.data.data
                this.loader_state = false
             })
        },
        methods: {
            ...mapActions([
                'getSlider',
            ]),
        }
    }

</script>

<style>

    #homepage_carousel .carousel-inner {
        max-height: 300px;
    }
    #homepage_carousel  ol li {
        outline: none !important;
    }

</style>