<template>
    <div id="home" class="px-md-5 px-3">
        <Loader :state="loader_state"></Loader>
        <t-bar></t-bar>
        <g-carousel></g-carousel>
        <l-block title="video series" :data="PlaylistsData"></l-block>
        <l-block title="audio series" :data="AudiosData"></l-block>
        <l-block title="videos" :data="GenVideosData"></l-block>
        <l-block title="articles"  :data="ArticlesData"></l-block>
        <l-block title="books"  :data="BooksData"></l-block>
    </div>
</template>

<script>
    import generalCarousel from "../components/public/generalCarousel";
    import Loader from '../components/shared/alhaq_loader'
    import topBar from "../components/public/topBar";
    import latestBlocks from "../components/public/latestBlocks";
    import { mapGetters, mapActions } from 'vuex'
    import topMostBar from "../components/public/topMostBar";

    export default {
        data() {
            return {
                loader_state: false,
            }
        },
        mounted(){
            this.loader_state = true
            this.getPlaylists({limit: 4})
            this.getBooks({limit: 4})
            this.getVideos({limit: 4})
            this.getAudioCollection({limit: 4})
            this.getArticles({limit: 4})
            this.loader_state = false

        },
        computed: {
             ...mapGetters([
                'PlaylistsData',
                'AudiosData',
                'GenVideosData',
                'BooksData',
                'ArticlesData'
            ]),
        },
        methods: {
            ...mapActions([
                'getPlaylists',
                'getBooks',
                'getVideos',
                'getArticles',
                'getAudioCollection'
            ])
        },
        components: {
            't-bar': topBar,
            'g-carousel': generalCarousel,
            'l-block' : latestBlocks,
            'Loader': Loader
        }
    }



</script>

<style scoped>
   
</style>