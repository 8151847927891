<template>
	<div id="topbar">
		<div class="row justify-content-start align-items-center my-md-0 my-4">
			<div class="col-md-1">
				<img
					src="../../assets/icons/menu.svg"
					class="img-fluid sidebar_icon float-right float-md-left float-xs-right"
					v-b-toggle.main_sidebar
					alt="menu"
				/>
			</div>
			<div class="col-md-3 text-center">
				<router-link :to="{ name: 'home' }">
					<img class="logoClass" src="../../assets/logos/logo.jpg" alt="" />
				</router-link>
			</div>
			<div class="col-md-8 col-12 text-center">
				<b-input-group class="search_group py-2" size="md">
					<b-input-group-prepend>
						<b-dropdown class="category_dropdown_main" :text="default_search_text.name">
							<b-dropdown-item
								class="py-1"
								v-for="(item, index) in filetype"
								:key="index"
								@click="default_search_text = item"
							>
								{{ item.name }}
							</b-dropdown-item>
						</b-dropdown>
					</b-input-group-prepend>
					<b-form-input v-model="search_text" class="category_search" placeholder="Search here..."> </b-form-input>
					<b-input-group-append>
						<b-button :disabled="!default_search_text.code" class="category_search_button" @click="search">
							<b-icon-search></b-icon-search>
						</b-button>
					</b-input-group-append>
				</b-input-group>
				<div class="d-block text-danger text-sm text-lowercase text-left">{{ this.search_errors }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data() {
		return {
			default_search_text: { name: 'Content Type', code: null },
			search_text: '',
			search_errors: '',
			filetype: [
				{ name: 'Video Series', code: 'PL' },
				{ name: 'Audio Series', code: 'AC' },
				{ name: 'Videos', code: 'GV' },
				{ name: 'Articles', code: 'AR' },
				{ name: 'Apps', code: 'AP' },
				{ name: 'Books', code: 'BK' },
			],
		}
	},
	mounted() {
		this.getCategories()
	},
	methods: {
		search() {
			this.perform_search({ type: this.default_search_text.code, value: this.search_text }).then((response) => {
				if (response.data.error) {
				} else {
					if (response.data.data.length) {
						this.$router.push({
							path: `/content-list/${this.default_search_text.name.toLowerCase()}`,
							query: {
								search: this.search_text.toString().trimEnd(),
							},
						})
					} else {
						this.search_errors = this.default_search_text.name + ' not found !'
						setTimeout(() => {
							this.search_errors = ''
						}, 5000)
					}
				}
			})
		},
		...mapActions(['getCategories', 'perform_search']),
	},
	computed: {
		...mapGetters(['CategoryData']),
	},
	components: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#topbar .row {
	background-color: #1b2735;
}

.search_group .btn-group >>> .btn {
	font-size: 14px !important;
	background-color: #28abe1 !important;
	border: 1px solid #28abe1 !important;
	box-shadow: none !important;
	font-weight: bolder;
	color: black !important;
	padding: 0px 20px;
	border-radius: 0 !important;
}
.search_group .category_search {
	background-color: transparent !important;
	color: white !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	font-family: brandFont-subcat;
}
.category_search:focus {
	border: 1px solid #28abe1 !important;
}
.search_group .category_search_button {
	font-size: 14px !important;
	background-color: #28abe1 !important;
	border: 1px solid #28abe1 !important;
	box-shadow: none !important;
	color: black !important;
	border-radius: 0 !important;
}
.sidebar_icon {
	width: 40px;
	height: 40px;
	cursor: pointer !important;
}
.logoClass {
	width: 150px;
	height: 150px;
}
.category_dropdown_main {
	font-family: brandFont-subcat;
}
.category_dropdown_main >>> ul {
	background-color: #1b2735 !important;
	max-height: 500px !important;
	overflow-x: auto;
	overflow-y: auto;
}
.category_dropdown_main >>> ul .dropdown-item {
	color: white;
	transition: border-left 0.2s ease-in-out;
	font-size: 13px !important;
}
.category_dropdown_main >>> ul .dropdown-item:hover {
	border-left: 5px solid #28abe1;
	background-color: transparent;
	color: #28abe1;
}
img:focus {
	outline: none !important;
}
</style>
