<template>
    <div class="latestBlocks mt-5">
        <div class="row no-gutters">
            <div class="col-md-12">
                <p id="lb-title" class="block_title text-white text-capitalize m-0">Latest {{title}}
                    <img v-if="title === 'video series'" src="../../assets/icons/series.svg" alt="video_series" class="ml-2 video_series_icon">
                    <img v-else-if="title === 'audio series'" src="../../assets/icons/audio.svg" alt="audio_series" class="ml-2 audio_series_icon">
                    <img v-else-if="title === 'videos'" src="../../assets/icons/videos.svg" alt="videos" class="ml-2 videos_icon">
                    <img v-else-if="title === 'articles'" src="../../assets/icons/articles.svg" alt="articles" class="ml-2 articles_icon">
                    <img v-else-if="title === 'books'" src="../../assets/icons/books.svg" alt="books" class="ml-2 books_icon">
                    <b-button @click="getData(title)" class="block_see_more mt-5 py-0 float-right">See All...</b-button>
                </p>
            </div>
        </div>
        <div class="row">
          <div class="col-md-3"  v-for="(d, index) in data" :key="index">
              <b-card
                class="block_cards bg-transparent mt-4"
                @click="GoToPage(d)"
                body-class="p-1"
              >
                  <div class="latest-card-img-wrapper">
                    <b-card-img  :src=baseUrl+d.thumbnail></b-card-img>
                  </div>
                  <b-button class="bg-transparent border-0 p-0 m-0 text-left" v-b-tooltip.hover.v-info :title="d.title">
                      <b-card-title class="textMaxLine mt-2 card_title">{{d.title}}</b-card-title>
                  </b-button>
                  <b-card-sub-title v-if="d.speaker_details" class="text-truncate mt-1 card_details">{{d.speaker_details.name}}</b-card-sub-title>
                  <b-card-sub-title v-if="d.speaker" class="text-truncate mt-1 card_details">{{d.speaker}}</b-card-sub-title>
                  <b-card-sub-title v-if="d.author_details" class="text-truncate mt-1 card_details">{{d.author_details.name}}</b-card-sub-title>
                  <b-button v-if="title != 'videos'" class="card_category px-1 py-0 mt-3 w-25">{{d.subcategory_details ? d.subcategory_details.name : ''}}</b-button>
              </b-card>
          </div>
        </div>
        <b-modal body-class="p-0 h-50 shadow" content-class="gen-video-content" header-close-variant="light" header-class="gen-video-header" footer-class="gen-video-footer" ref="gen-player-modal" no-close-on-backdrop @close="gen_src = null" title="Playing Video"  size="lg">
            <iframe id="gen_player" :src="gen_src" frameborder="0" allowfullscreen></iframe>
        </b-modal>
    </div>
</template>

<script>

    import config from '../../assets/config';
    export default {
        props: ['title', 'data'],
        data() {
            return {
                baseUrl: config.baseURL,
                items: ['asd', 'asd'],
                yt_url: 'https://www.youtube.com/embed/',
                gen_src: ''
            }
        },
        computed: {
            defaultMediaThumb() {
                return require('../../assets/thumbnails/latest_block_default.png')
            },

            defaultBookThumb() {
                return require('../../assets/thumbnails/latest_books_default.png')
            }
        },
        methods: {
            getData(title){
                this.$router.push({
                name: "content-list",
                params: { title: title }
             });
            },
            GoToPage (d) {
                if (this.title === 'video series') {
                    this.$router.push({name:'watch-playlist', params: {
                            pl_id: d.id,
                            subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
                            tab_index: 0
                        }})
                } else if (this.title === 'audio series') {
                    this.$router.push({name:'listen-audio', params: {
                            audioc_id: d.id,
                            subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
                            tab_index: 1
                        }})
                } else if (this.title === 'articles') {
                    this.$router.push({name:'read-article', params: {
                            art_id: d.id,
                            subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
                            tab_index: 2
                        }})
                }
                else if (this.title === 'books') {
                    this.$router.push({name:'sub-category', params: {
                            id: d.subcategory_details ? d.subcategory_details.id : '',
                            tab_index: 3
                        }})
                } else if (this.title === 'videos') {
                    this.gen_src = this.yt_url+d.url+'?rel=0&autoplay=1'
                    this.$refs['gen-player-modal'].show()
                }
            }
        },
    }
</script>

<style scoped>
    .textMaxLine{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 30px;
        overflow: hidden;
        word-break: break-word;
    }
    .block_title  {
        font-size: 30px !important;
        font-family: brandFont-bold;
    }
    .block_title::before {
        font-family: brandFont;
        font-size: 25px !important;
        content: '';
        margin: 0px 15px 0px 0px;
        padding: 0px;
        border-left: 8px solid #28abe1;
    }
    .block_title::after {
        content: ' ';
        margin-top: 15px;
        display: block;
        width: 94%;
        border: .5px solid white;
    }
    .divider {
        border-bottom: .5px solid white;
        width: 93%;
    }
    .card {
        border: none !important;
    }
    .card .card-title {
        color: #28abe1;
        font-family: brandFont-cat;
    }
    .card .card-subtitle {
        color: white !important;
    }
    .card_title,
    .card_details {
        font-size: 14px;
    }
    .card_category {
        min-width: fit-content;
        border: 1px solid gray;
        color: whitesmoke !important;
        background-color: transparent !important;
        font-size: 12px !important;
    }
    .block_see_more {
        font-size: 11.5px !important;
        font-weight: 600;
        min-width: fit-content;
        color: black !important;
        background-color: white !important;
        box-shadow: none !important;
        font-family: brandFont-subcat;
    }
    .block_see_more:hover {
        color: white !important;
        background-color: #28abe1 !important;
    }
    .card:hover {
      overflow: hidden;
      cursor: pointer;
    }
    .text-capitalize {
        font-variant-caps: all-petite-caps;
    }
    .latest-card-img-wrapper {
        overflow: hidden;
    }
    .video_series_icon,
    .audio_series_icon,
    .articles_icon,
    .books_icon,
    .videos_icon
    {
        width: 40px !important;
        height: 40px !important;
    }
    #gen_player {
        width: 100%;
        max-height: 380px;
        height: 380px;
        border-bottom: none !important;
    }
</style>